<template>
    <div class="big">
        <!-- 拒收弹框 -->
        <!-- <el-dialog :visible.sync="updateCategory" title="拒收原因" :show-close="false">
            <div class="flexPullDown">
                <div class="ioo">拒收类型<div style="font-weight: 600;">：</div>
                </div>
                <select v-model="selectedItem" @change="changePull" class="pullDown">
                    <option value="">请选择拒收类型</option>
                    <option v-for="(item, index) in pullDown" :key="index" :value="item.id">{{ item.name }}</option>
                </select>
            </div>
            <el-input type="textarea" :rows="4" placeholder="请输入拒收原因" v-model="rejectionTextarea">
            </el-input>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="save()">保 存</el-button>
            </span>
        </el-dialog> -->
        <!-- 添加商品弹框 -->
        <!-- <el-dialog title="提示" :visible.sync="addGoodsFlag" width="30%">
            <el-form :model="goodsForm">
                <el-form-item label="商品类别">
                    <el-select v-model="goodsForm.goodsType" placeholder="请选择商品类型">
                        <el-option v-for="(item, index) in goodsTypeList" :label="item.title"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="subNewGoods">确定提交</el-button>
            </span>
        </el-dialog> -->


        <div class="bigPhoto" v-if="showBigPhoto">
            <img style="width: 1500px; height: 800px;" :src="bigPhotoUrl" alt="">
        </div>
        <div class="left">
            <div class="left-top">
                <el-input ref="inputRef" v-model="Rfid" placeholder="请扫描或输入中转码"></el-input>
                <el-button type="primary" @click="searchInfo">查询</el-button>
            </div>
            <div class="left-content" v-if="orderInfo.id">
                <div style="font-size: 24px;">
                    <div class="orderFlag" v-if="orderInfo.is_vip == 1">
                        <p>洗护卡订单</p>
                    </div>
                    <div class="orderFlag" v-if="orderInfo.is_vip == null && orderInfo.to_back_id == null">
                        <p>普通订单</p>
                    </div>
                    <div class="orderFlag" v-if="orderInfo.is_vip == null && orderInfo.to_back_id">
                        <p>反洗订单</p>
                    </div>
                </div>
                <div :class="isTime ? 'left-content-top  timeOut' : 'left-content-top'">
                    <p>订单编号:</p>
                    <span style="color:#333333; font-size: 16px;">{{ orderInfo.number }}</span>
                    <p>中转码:<span style="color:#333333;">{{ orderInfo.rfid }}</span></p>
                    <p>订单状态:
                        <span style="color: #3278FF;" v-if="orderInfo.status == 20">已揽收送货(工厂)中</span>
                        <span style="color: #3278FF;" v-if="orderInfo.status == 22">工厂已收货</span>
                        <span style="color: #3278FF;" v-if="orderInfo.status == 23">工厂清洗中</span>
                        <span style="color: #3278FF;" v-if="orderInfo.status == 23">清洗完成</span>
                    </p>
                    <p>手机号:<span style="color:#333333;">{{ orderInfo.address.contact_number }}</span></p>
                    <!-- <p>地址:{{ orderInfo.address.street_address }}</p> -->
                    <p>注意事项:{{ orderInfo.content }}</p>
                    <p>备注:{{ orderInfo.content2 }}</p>
                </div>
                <div class="left-content-top"
                    style=" display: flex; justify-content: space-between; margin-bottom: 20px;"
                    v-if="orderInfo.is_vip && orderInfo.goodsList.length > 0">
                    <p>泡泡余额:<span style="color: #3278FF;">{{ orderInfo.userCard.nums }}</span></p>
                    <p>消耗泡泡:<span style="color: #3278FF;">{{ allGoodsPops }}</span></p>
                </div>
                <!-- <div v-if="orderInfo.is_vip && orderInfo.goodsList.length == 0"> -->
                <div v-if="orderInfo.is_vip">
                    <!-- 登录件数<el-input v-model="subGoodsNew" placeholder="输入登录件数"></el-input> -->
                    登录件数:<input type="text" v-model="subGoodsNew">
                    <!-- 登陆件数:<input style="width:50px" type="text" v-model="goodsNum">
                    <el-button style="margin-left: 100px;" type="warning" size="mini" @click="subAddGoods">提交</el-button>
                    <p style="margin-top: 20px;">会员订单请选择登录件数添加商品</p> -->
                </div>
                <div class="left-content-bottom">
                    <div :class="goodsIndex == index ? 'list list-add' : 'list'" @click="getGoodsInfo(index)"
                        v-for="(item, index) in orderInfo.goodsList" :key="index">
                        <ul :class="!item.factory_use_info == '' ? 'list-ul alreadySub' : 'list-ul'"
                            style="width: 100%;">
                            <li>商品ID:{{ item.id }}</li>
                            <li>商品类型:{{ item.title }}</li>
                            <li v-if="item.status == 12">商品状态:<span style="color: aquamarine;">已收件</span></li>
                            <li v-if="item.status == 20">商品状态:<span style="color: aquamarine;">清洗中</span></li>
                            <li v-if="item.status == 21">商品状态:<span style="color: aquamarine;">清洗完成</span></li>
                            <!-- <li v-if="!item.factory_use_info == ''" style="color:red;font-size:12px;margin-top:20px">
                                已提交，重复提交更新商品信息</li> -->
                        </ul>
                        <ul class="list-ul">
                            <li style="margin-top:10px">
                                <el-button type="danger" size="mini"
                                    @click="openDeleteGoods(item.id, orderInfo.rfid)">删除</el-button>
                            </li>
                            <!-- <li style="margin-top:10px">
                                <el-switch v-model="switchFlag" active-text="洗"></el-switch>
                            </li> -->
                            <!-- <li style="margin-top:10px">
                                <el-button type="warning" size="mini" @click="subGoods(item.id)">提交</el-button>
                            </li> -->
                        </ul>
                    </div>
                </div>
                <div class="left-content-btn">
                    <!-- <el-button type="success" @click="sendGoodsAdd">添加商品</el-button> -->
                    <!-- <el-button type="danger" @click="rejection()">拒收订单</el-button> -->
                    <el-button type="info" @click="unbind">拆包完成</el-button>
                </div>
            </div>
            <div v-if="!orderInfo.id">
                <el-empty description="暂无订单"></el-empty>
            </div>
        </div>
        <div class="right">
            <div class="right-top">
                <el-tabs @tab-click="getRisk" v-model="activeName" :stretch="true" type="border-card">
                    <el-tab-pane label="分类" name="one">
                        <div class="allCloth">
                            <el-button v-if="orderInfo.is_vip" @click="subGoodsType" type="primary">提交类型</el-button>
                            <div class="right-top-title">
                                <ul v-if="goodsTypeList.length != 0">
                                    <li v-for="(item, index) in clothType" :key="index"
                                        :class="clothindex == index ? 'clothTypeList add' : 'clothTypeList'"
                                        @click="getClothIndex(index)">{{ item.name }}</li>
                                </ul>
                            </div>
                            <div class="cloth" v-if="clothindex == 0">
                                <div :class="tyIndex == index ? 'clothItem ccc' : 'clothItem'"
                                    v-for="(item, index) in jacket" @click="getClothType(item.title, item.id, index)">
                                    <img :src="`https://jiekexihu.oss-cn-beijing.aliyuncs.com/${item.image}`" alt="">
                                    <span>{{ item.title }}</span>
                                </div>
                            </div>
                            <div class="cloth" v-if="clothindex == 1">
                                <div :class="tyIndex == index ? 'clothItem ccc' : 'clothItem'"
                                    v-for="(item, index) in pants" @click="getClothType(item.title, item.id, index)">
                                    <img :src="`https://jiekexihu.oss-cn-beijing.aliyuncs.com/${item.image}`" alt="">
                                    <span>{{ item.title }}</span>
                                </div>
                            </div>
                            <div class="cloth" v-if="clothindex == 2">
                                <div :class="tyIndex == index ? 'clothItem ccc' : 'clothItem'"
                                    v-for="(item, index) in shoe" @click="getClothType(item.title, item.id, index)">
                                    <img :src="`https://jiekexihu.oss-cn-beijing.aliyuncs.com/${item.image}`" alt="">
                                    <span>{{ item.title }}</span>
                                </div>
                            </div>
                            <div class="cloth" v-if="clothindex == 3">
                                <div :class="tyIndex == index ? 'clothItem ccc' : 'clothItem'"
                                    v-for="(item, index) in home" @click="getClothType(item.title, item.id, index)">
                                    <img :src="`https://jiekexihu.oss-cn-beijing.aliyuncs.com/${item.image}`" alt="">
                                    <span>{{ item.title }}</span>
                                </div>
                            </div>
                            <div class="cloth" v-if="clothindex == 4">
                                <div :class="tyIndex == index ? 'clothItem ccc' : 'clothItem'"
                                    v-for="(item, index) in other" @click="getClothType(item.title, item.id, index)">
                                    <img :src="`https://jiekexihu.oss-cn-beijing.aliyuncs.com/${item.image}`" alt="">
                                    <span>{{ item.title }}</span>
                                </div>
                            </div>
                            <div class="cloth" v-if="clothindex == 5">
                                <div :class="tyIndex == index ? 'clothItem ccc' : 'clothItem'"
                                    v-for="(item, index) in littlecloth"
                                    @click="getClothType(item.title, item.id, index)">
                                    <img :src="`https://jiekexihu.oss-cn-beijing.aliyuncs.com/${item.image}`" alt="">
                                    <span>{{ item.title }}</span>
                                </div>
                            </div>
                        </div>

                    </el-tab-pane>
                    <el-tab-pane label="拍照" name="two">
                        <div class="pohtoArea">
                            <div class="videoArea">
                                <div class="videoArea-video">
                                    <video class="videoNow" ref="video" width="590" height="440" autoplay></video>
                                    <canvas class="canvas" ref="canvas" width="3264" height="2448"></canvas>
                                </div>
                                <div class="videoArea-btn-list">
                                    <button @click="captureImage"
                                        style="background-color: #3278FF ; border: none; border-radius: 4px;">拍照</button>
                                    <button @click="uploadPhotos"
                                        style="margin-left: 20px; background-color: #D8D8D8 ; border: none; border-radius: 4px;">上传</button>
                                </div>
                            </div>
                            <div class="photoItem">
                                <div v-for="(item, index) in Chaibaohotos" :key="index"
                                    :class="index == 0 ? 'item item-add' : 'item'">
                                    <button style="position: absolute;width: 20px;height: 20px; right: 4px;"
                                        @click="deletePhoto(index)">x</button>
                                    <img @click="toBig(index)" :src="item.url" alt="">
                                </div>
                            </div>

                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="颜色" name="seven">
                        <div class="colorList">
                            <ul>
                                <li :class="colorIndex == index ? 'colorListItem  xxx' : 'colorListItem'"
                                    v-for="(item, index) in colorList" :key="index" @click="getColor(item, index)">{{
                                        item }}</li>
                            </ul>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="附件" name="three">
                        <div class="fileList">
                            <ul>
                                <li :class="fileIndexList.includes(index) ? 'fileListItem xxx' : 'fileListItem'"
                                    v-for="(item, index) in fileList" :key="index" @click="getFile(item, index)">{{ item
                                    }}</li>
                            </ul>
                            <!-- <input type="text" placeholder="自定义附件" v-model="inputFile">
                            <button @click="subInputFile">确定</button> -->
                            <p>已选附件:</p>
                            <ul style="height: 60px;">
                                <li class="fileListItem" v-for="(item, index) in checkClothfiles" :key="index"
                                    @click="printFile(item, index)">{{ item }}</li>
                            </ul>
                            <div class="annex" v-if="filePrint && showFilePrint">
                                <div style="display: flex;justify-content: space-between;">
                                    <canvas style="display: none;" ref="barcodeCanvasAnnex"></canvas>
                                    <span>附件水洗唛预览:</span>
                                    <el-button type="primary" size="mini" @click="handlePrint(2)">打印</el-button>
                                </div>
                                <div class="annexPrint" id="printAnnex">
                                    <div style="display: inline-block;">
                                        <p style="margin-left: 20px;">{{ orderInfo.number }}</p>
                                        <img style="margin-left: 20px; width: 200px; height: 80px;"
                                            :src="barcodeUrlAnnex" alt="">
                                    </div>
                                    <div style="display: inline-block; margin-left: 20px;">
                                        附件名称:{{ filePrint }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="瑕疵" name="four">
                        <div class="flawList">
                            <ul>
                                <li :class="flawlistIndex.includes(index) ? 'flawListItem xx' : 'flawListItem'"
                                    v-for="(item, index) in flawList" :key="index" @click="getFlaw(item, index)">{{ item
                                    }}</li>
                            </ul>
                            <!-- <input type="text" v-model="inputFlaw" placeholder="自定义瑕疵">
                            <button @click="subInputFlaw">确认</button> -->
                        </div>
                    </el-tab-pane>


                    <el-tab-pane label="洗涤风险" name="five">
                        <!-- <div class="riskList">
                            <el-input style="margin-top: 20px;" type="textarea" :rows="2" placeholder="自定义洗涤风险"
                                v-model="riskContent">
                            </el-input>
                        </div> -->
                        <div class="riskList">
                            <div class="riskList-left">
                                <div style="margin-bottom: 10px;">
                                    <span style="margin-right: 10px;">是否为奢侈品:</span>
                                    <el-switch v-model="isLuxuryGoods"></el-switch>
                                </div>
                                <select style="margin-left: 0px;" v-model="selectedItem" @change="changePull"
                                    class="pullDown">
                                    <option value="">请选择风险类型</option>
                                    <option v-for="(item, index) in pullDown" :key="index" :value="item.id">{{ item.name
                                        }}</option>
                                </select>
                                <!-- <el-select v-model="selectedItem" placeholder="请选择原因">
                                    <el-option v-for="(item, index) in pullDown" :key="index"
                                        :value="item.id">{{ item.name }}</el-option>
                                </el-select> -->
                                <el-input style="margin-top: 20px;" type="textarea" :rows="2" placeholder="自定义洗涤风险"
                                    v-model="riskContent"></el-input>
                                <p>已选照片:</p>
                                <ul class="riskImgList">
                                    <li class="riskImgList-item" v-for="(item, index) in checkRiskArr" :key="index">
                                        <button @click="deleteRiskPhoto(index)">x</button>
                                        <img :src="item" alt="">
                                    </li>
                                </ul>
                                <el-button @click="subRisk" type="primary">提交洗涤风险</el-button>
                            </div>
                            <div class="riskList-right">
                                <ul class="riskList-rightImg">
                                    <li @mouseenter="handleMouseOver(index)" @mouseleave="handleMouseOut"
                                        v-for="(item, index) in riskPhoto" class="riskList-rightImg-item">
                                        <img @click="toBig(index)" :src="item" alt="">
                                        <div v-if="showRiskBtn === index" class="riskList-btn"
                                            @click="checkRickPhoto(item)">选择</div>
                                    </li>
                                </ul>
                            </div>
                            <div></div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="备注" name="six" style="height: 530px;">
                        <el-input style="margin-top: 20px;" type="textarea" :rows="4" placeholder="自定义备注"
                            v-model="notesContent">
                        </el-input>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="printArea" v-if="checkOrderId">
                <div class="host">
                    <div style="display: flex;justify-content: space-between;">
                        <canvas style="display: none;" ref="barcodeCanvas"></canvas>
                        <span>主水洗唛预览:</span>
                        <el-button type="primary" size="mini" @click="handlePrint(1)">打印</el-button>
                    </div>
                    <div class="hostPrint" id="printHost" style="display: flex;">
                        <div style="margin-left: 20px;">
                            <span>拆包时间:{{ entryTime }}</span>
                            <p style="margin-left: 20px;">{{ orderInfo.number }}</p>
                            <img style="margin-left: 20px; width: 200px; height: 80px;" :src="barcodeUrl" alt="">
                            <p>{{ checkOrderId }}</p>
                        </div>
                        <div style="width: 600px;margin-left: 10px; font-size: 24px;">
                            <span v-if="!orderInfo.is_vip">[<span>{{ orderInfo.goodsList.length }}</span>-<span>{{
                                goodsIndex + 1 }}</span>]</span>
                            <span v-if="orderInfo.is_vip">[<span>{{ subGoodsNew }}</span>-<span>{{ goodsIndex + 1
                                    }}</span>]</span>
                            <span v-if="this.factoryInfo.goodsType && !this.checkClothTypes">洗护类别:{{
                                factoryInfo.goodsType }}</span>
                            <span v-else>洗护类别:{{ checkClothTypes }}</span>|
                            <span v-if="this.factoryInfo.goodsColor && !this.checkClothColor">颜色:{{
                                factoryInfo.goodsColor }}</span>
                            <span v-else>颜色:{{ checkClothColor }}</span>|
                            <span v-if="this.factoryInfo.goodsFlaw && !this.checkFlawlist.length">瑕疵:{{
                                factoryInfo.goodsFlaw }}</span>
                            <span v-else>瑕疵:{{ checkFlawlist }}</span>|
                            <span v-if="this.factoryInfo.goodsFiles && !this.checkClothfiles.length">附件:{{
                                factoryInfo.goodsFiles }}</span>
                            <span v-else>附件:{{ checkClothfiles }}</span>|
                            <span v-if="this.factoryInfo.goodsNotes && !this.notesContent">备注:{{
                                factoryInfo.goodsNotes }}</span>
                            <span v-else>备注:{{ notesContent }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import OSS from 'ali-oss';
import { VuePrintNext } from 'vue-print-next';
export default {
    name: '',
    components: {

    },
    data() {
        return {
            Rfid: '',
            entryTime: '',
            orderInfo: {},
            checkOrderId: '',
            indexNum: '',
            activeName: 'one',
            switchFlag: true,
            Chaibaohotos: [],
            capturedPhotos: [],
            ossClient: null,
            ossFile: {},
            photoUrl: '',
            ossUrls: [],
            clothType: [
                { name: '上装', value: 0 },
                { name: '下装', value: 1 },
                { name: '鞋类', value: 2 },
                { name: '家纺', value: 3 },
                { name: '其他', value: 4 },
                { name: '小件衣物', value: 5 }

            ],
            jacket: [], //上装
            pants: [], //下装
            shoe: [], //鞋类
            home: [], //家纺
            other: [], //其他
            littlecloth: [], //小件衣物
            fileList: ['盒子', '手提袋', '尘袋', '身份卡', '挂饰', '包内遗留物', '包内胆', '包撑', '鞋撑', '单层帽', '毛帽边', '袖筒', '有袖内胆', '无袖内胆',
                '下身', '毛领', '衣领', '鞋带', '帽子', '另一条袖带', '另一条肩带', '领子袋', '另一条毛领', '毛边', '另一条毛边', '毛袖', '另一条毛袖', '内胆',
                '活袖', '另一条活袖', '帽绳', '腰绳', '底绳', '活绳', '毛球', '另一个毛球', '蝴蝶结', '另一条鞋带', '另一条鞋垫', '下摆', '扣子', '垫扣',
                '拉链头', '袖带', ' 袜子 ', '无'],
            flawList: [
                '掉扣子', '扣掉漆', '扣掉漆', '掉绒', '拉锁掉漆', '纽扣掉色',
                '磨损', '磨花', '磨烂', '磨伤', '磨破', '鞋底磨破',
                '压胶破损', '鞋边破损',
                '变形',
                '划痕',
                '折痕',
                '印花开裂', '折痕开裂', '皮面开裂', '开线', '开胶', '开胶', '开线',
                '皮面起泡',
                '渍尽洗', '脏尽洗', '白渍尽去', '黄渍有印', '污渍', '油渍', '泥渍', '黑渍', '笔渍', '茶渍', '白渍', '墨渍', '墨渍', '果渍', '酒渍', '黄渍', '血渍', '奶渍', '红渍', '绿渍', '有渍', '红蜡烛渍', '咖啡渍', '机油渍', '乌鸡渍', '油漆渍', '蓝渍', '胶渍', '血渍',
                '发黄', '氧化发黄',
                '划破',
                '霉斑', '锈迹',
                '蛀洞', '烫洞',
                '鞋带坏', '拉锁坏', '日照褪色', '褪色', '褪色', '易褪色', '咬色', '甩色', '搭色', '串色', '阴阳色', '染色', '多处搭色', '少色', '色花',
                '光泽差',
                '断底',
                '起毛', '起球',
                '反光',
                '压胶',
                '涂层',
                '勾线', '勾丝', '甩丝',
                '有孔', '有烂',
                '修补过',
                '无衣物', '无'
            ],
            riskList: ['有可能洗破', '有可能会掉色', '洗后涂层脱落/开胶', '洗后压胶开胶', '洗后发白', '洗后自相染色', '洗后PU破损',
                '洗后扣掉漆', '洗后污渍显划痕', '洗后装饰物易坏', '洗后常磨处破损', '洗后破损扩大'],
            colorList: ['米白', '米黄', '浅灰色', '青灰色', '墨绿', '浅蓝', '天蓝', '海军蓝', '湖蓝', '藏青', '雪青色', '浅白',
                '奶白', '灰白', '黑色', '灰黑', '黑夹灰', '橙色', '驼色', '砖色', '花色', '格子', '黑白格', '千鸟格', '条纹', '卡通图案', '白色', '白夹黑',
                '黄色', '红色', '桃红', '粉色', '蓝色', '深蓝', '草绿', '军绿', '灰色', '银灰色', '棕色', '咖啡色', '卡其色', '杏色', '紫色', '紫红'],
            riskContent: '',
            // inputFlaw: '',
            // inputFile: '',
            notesContent: '无',
            barcodeUrl: '',
            barcodeUrlAnnex: '',
            showBigPhoto: false,
            bigPhotoUrl: '',
            clothindex: 0,
            checkClothTypes: '',
            checkClothColor: '',
            checkClothfiles: [],
            filePrint: '',
            fileIndex: '',
            showFilePrint: false,
            colorIndex: 'x',
            fileIndexList: [],
            checkFlawlist: [],
            flawlistIndex: [],
            updateCategory: false,
            pullDown: [],
            rejectionTextarea: '',
            selectedItem: '',
            goodsIndex: 0,

            addGoodsFlag: false,
            goodsForm: {
                goodsType: ''
            },
            goodsTypeList: [],
            goodsNum: "",
            allGoodsPops: '',
            selectList: [],
            // 以下改进拆包
            newGoodsId: '',
            subGoodsNew: '',
            tyIndex: 'xx',
            factoryInfo: {},
            // 超时
            isTime: false,
            // 洗涤风险相关
            riskPhoto: [],
            showRiskBtn: null,
            checkRiskArr: [],
            isLuxuryGoods: false
        }
    },
    props: {
        indexFlag: {
            type: String
        },
    },
    watch: {
        indexFlag(newValue, oldValue) {
            this.indexNum = newValue;
            this.$refs.inputRef.focus();
        },
        checkClothfiles(newValue, oldValue) {
            if (newValue.length > 0) {
                this.showFilePrint = true;
            } else {
                this.showFilePrint = false;
            }
        },
        immediate: true,
        deep: true
    },
    mounted() {
        // this.sendGoodsAdd();
        this.getEntryTime();
        this.configureOSSClient();
        this.$nextTick(() => {
            window.addEventListener('keyup', (e) => {
                if (e.repeat) {
                    return;
                }
                if (e.keyCode == 13) {
                    this.searchInfo();
                } else if (e.keyCode == 27) {
                    this.showBigPhoto = false
                } else if (e.keyCode === 75) {
                    this.captureImage();
                }
                this.orderId += String.fromCharCode(e.keyCode);
            })
        })
    },
    updated() {
        // this.sendGoodsAdd();
        this.startCamera();
        this.getEntryTime();
        if (this.orderInfo.goodsList) {
            this.generateBarcode();
            this.generateBarcodeAnnex();
        }
    },
    computed: {

    },
    methods: {
        // 提交洗涤风险
        subRisk() {
            let luxuryGoodsNum = 0;
            if (this.isLuxuryGoods) {
                luxuryGoodsNum = 1
            } else {
                luxuryGoodsNum = 0
            }
            let photo = this.checkRiskArr.join(',');
            let obj = {
                content: this.riskContent,
                ogid: this.checkOrderId,
                id: localStorage.getItem('id'),
                status: this.selectedItem,
                risk: photo,
                isLuxuryGoods: luxuryGoodsNum
            };

            if (this.riskContent == '') {
                this.$message({
                    message: '请填写洗涤风险',
                    type: 'error'
                })
            } else if (this.selectedItem == '') {
                this.$message({
                    message: '请选择原因类型',
                    type: 'error'
                })
            } else if (this.checkRiskArr.length == 0) {
                this.$message({
                    message: '请选择风险照片',
                    type: 'error'
                })
            } else {
                this.$http.post('receiving/washingRisk', obj, (res => {
                    if (res.code == 1) {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        })
                    }
                }))
                this.riskContent = '';
                this.selectedItem = '';
                this.checkRiskArr = [];
                this.riskPhoto = [];
                this.isLuxuryGoods = false;
                this.activeName = 'six';
            }
        },
        // 删除已选风险照片
        deleteRiskPhoto(index) {
            this.checkRiskArr.splice(index, 1);
        },
        //选择风险照片
        checkRickPhoto(url) {
            console.log(url);
            let photoRepeat = this.checkRiskArr.filter(item => item == url);
            if (photoRepeat.length == 0) {
                this.checkRiskArr.push(url);
            } else {
                this.$message({
                    message: '请勿重复选择',
                    type: 'error'
                })
            }
        },
        // 移入洗涤风险图片
        handleMouseOver(index) {
            this.showRiskBtn = index;

        },
        // 移出洗涤风险图片
        handleMouseOut() {
            this.showRiskBtn = null;
        },
        // 点击洗涤风险回调
        getRisk() {
            if (this.activeName == 'five') {
                let that = this;
                that.$http.post('receiving/getstatus', {}, function (res) {
                    // this.pullDown = data.data
                    console.log(res);
                    that.pullDown = res.data

                })
            }
        },
        // 查询订单时间
        searchOrderTome(id) {
            console.log(id);
            this.$http.post('order/getOrderTracking', { order_id: id, status: '22' }, (res => {
                if (res.code == 1) {
                    let time = res.data.data.filter(item => item.statusDescription == '工厂已收货')[0].changeTime
                    let orderDate = new Date(time);
                    let timeoutDate = new Date(orderDate.getTime() + 24 * 60 * 60 * 1000);
                    let now = new Date();
                    const nowTime = now.toLocaleString();
                    let changeNow = new Date(nowTime)
                    this.isTime = changeNow > timeoutDate
                    console.log(this.isTime);
                }
            }))
        },
        subGoodsType() {
            let orderID = this.orderInfo.id;
            let userID = localStorage.getItem('id');
            let goodsID = this.newGoodsId;
            console.log(orderID, userID, goodsID);
            this.$http.post('order/addGoods', {
                uid: userID,
                orderId: orderID,
                goodsId: goodsID
            }, (res => {
                console.log(res);
                if (res.code == 1) {
                    this.$message({
                        type: 'success',
                        message: '生成商品决定类型成功'
                    })
                    this.searchInfo();
                    this.activeName = 'two';
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg
                    })
                }
            }))
            setTimeout(() => {
                this.checkOrderId = this.orderInfo.goodsList[this.orderInfo.goodsList.length - 1].id;
                this.goodsIndex = this.orderInfo.goodsList.length - 1;
            }, 1000);
        },
        // subInputFile() {
        //     this.checkClothfiles.push(this.inputFile);
        //     this.inputFile = ''
        // },
        // subInputFlaw() {
        //     this.checkFlawlist.push(this.inputFlaw);
        //     this.inputFlaw = ''
        // },
        // 拆包完成回调
        unbind() {
            const goodsFlag = this.orderInfo.goodsList.every(item => item.factory_use_info != undefined);
            let data = {
                rfid: this.orderInfo.rfid,
                ogid: this.orderInfo.id,
                id: localStorage.getItem('id')
            }
            if (this.orderInfo.goodsList.length == 0) {
                this.$message({
                    message: '无商品,不能完成拆包'
                })
            } else {
                if (goodsFlag) {
                    this.$http.post('/receiving/unbindput', data, (res => {
                        if (res.code == 1) {
                            this.$message({
                                message: '拆包完成,请扫描其他订单',
                                type: 'success'
                            })
                            this.orderInfo.id = '';
                            this.checkClothTypes = '';
                            this.checkClothColor = '';
                            this.riskContent = '';
                            this.notesContent = '';
                            this.flawlistIndex = [],
                                this.checkClothfiles = [];
                            this.checkFlawlist = [];
                            this.fileIndexList = [];
                            this.activeName = 'one';
                            this.$refs.inputRef.focus();
                            this.subGoodsNew = '';
                            this.checkOrderId = '';
                        } else {
                            this.$message({
                                message: res.msg,
                                type: 'error'
                            })
                        }
                    }))
                } else {
                    this.$message({
                        message: '订单下商品未录入完全,不能拆包',
                        type: 'error'
                    })
                }
            }
        },
        // 获取入厂时间
        getEntryTime() {
            const now = new Date();
            const year = now.getFullYear();
            const month = now.getMonth() + 1;
            const day = now.getDate();
            const time = now.toLocaleTimeString();
            this.entryTime = `${year}-${month}-${day} ${time}`;
        },
        // 提交商品回调
        subGoods(ogID) {
            let data = {
                goodsType: this.checkClothTypes,
                goodsColor: this.checkClothColor,
                goodsFiles: this.checkClothfiles,
                goodsFlaw: this.checkFlawlist,
                goodsRisk: this.riskContent,
                goodsNotes: this.notesContent,
                goodsRecord: [{ time: this.entryTime, uid: JSON.parse(localStorage.getItem('userinfo')).id, work: '拆包登记' }]
            };
            let params = JSON.stringify(data);
            if (data.goodsType == "" && data.goodsColor == "") {
                this.$message({
                    message: '打印水洗唛，不更新商品信息',
                    type: 'success'
                })
            } else {
                this.$http.post('order/updateGoodsFactoryData', { ogId: ogID, data: params }, (res => {
                    if (res.code == 1) {
                        this.$message({
                            message: '提交成功,请选择其他商品录入',
                            type: 'success'
                        })
                        this.checkClothTypes = '';
                        this.checkClothColor = '';
                        this.colorIndex = 'x';
                        this.flawlistIndex = [],
                            this.checkClothfiles = [];
                        this.checkFlawlist = [];
                        this.fileIndexList = [];
                        this.activeName = 'one';
                        this.riskContent = '';
                        this.notesContent = '';
                        this.tyIndex = 'xx';
                        this.checkOrderId = '';
                        this.searchInfo();
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        })
                    }
                }))
            }

        },
        // 登陆件数提交回调
        subAddGoods() {
            if (this.goodsNum == '1' || this.goodsNum == '2' ||
                this.goodsNum == '3' || this.goodsNum == '4' || this.goodsNum == '5') {
                // let num = this.goodsNum;
                let orderID = this.orderInfo.id;
                let userID = localStorage.getItem('id');
                let goodsID = 52;
                let xx = 0;
                let test = this.goodsNum;
                this.$message({
                    message: '正在生成商品,请稍等',
                    type: 'success'
                });
                let times = setInterval(() => {
                    xx++;
                    this.$http.post('order/addGoods', { uid: userID, orderId: orderID, goodsId: goodsID }, (res => {
                        if (xx == test) {
                            clearInterval(times);
                            this.searchInfo();
                        }
                    }))
                }, 1050);
                this.goodsNum = '';
            } else {
                this.$message({
                    message: '请输入数字且件数不超过5',
                    type: 'error'
                })
            }
        },
        // 点击添加商品回调
        sendGoodsAdd() {
            this.$http.post('goods/select', { switch: 1, levels: [1, 2, 3], pageSize: 1000, notDisplayGoodType: 3 }, (res => {
                if (res.code == 1) {
                    this.goodsTypeList = res.data;
                    console.log(res.data.filter(item => item.goods_type_id == 3 || item.goods_type_id == 18));
                    this.jacket = res.data.filter(item => item.goods_type_id == 1 || item.goods_type_id == 13);
                    this.pants = res.data.filter(item => item.goods_type_id == 9 || item.goods_type_id == 15);
                    this.shoe = res.data.filter(item => item.goods_type_id == 2 || item.goods_type_id == 16);
                    this.home = res.data.filter(item => item.goods_type_id == 6);
                    this.other = res.data.filter(item => item.goods_type_id == 17);
                    this.littlecloth = res.data.filter(item => item.goods_type_id == 19);
                }
            }))
        },
        // 添加商品确定回调
        subNewGoods() {
            let orderID = this.orderInfo.id;
            let userID = localStorage.getItem('id');
            let goodsID = this.goodsForm.goodsType;
            this.$http.post('order/addGoods', { uid: userID, orderId: orderID, goodsId: goodsID }, (res => {
                if (res.code == 1) {
                    this.searchInfo();
                    this.addGoodsFlag = false;
                    this.$message({
                        type: 'success',
                        message: '添加成功!'
                    });
                    this.goodsForm.goodsType = ''
                }
            }))
        },
        // 点击商品列表回调
        getGoodsInfo(index) {
            this.checkOrderId = this.orderInfo.goodsList[index].id;
            this.goodsIndex = index;
            if (JSON.parse(this.orderInfo.goodsList[index].factory_use_info) == null) {
                this.factoryInfo = {}
            } else {
                this.factoryInfo = JSON.parse(this.orderInfo.goodsList[index].factory_use_info);
            }
        },
        // 拒收回调
        rejection() {
            const goodsFlag = this.orderInfo.goodsList.every(item => item.factory_use_info != undefined);
            if (goodsFlag) {
                this.updateCategory = true;
                let stat = this
                this.$http.post('receiving/getstatus', {}, function (data) {
                    stat.pullDown = data.data
                })
            } else {
                this.$message({
                    message: '请先打印水洗唛提交信息再拒收',
                    type: 'error'
                })
            }

        },
        changePull() {
            console.log(this.selectedItem);
        },
        save() {
            if (this.rejectionTextarea == '') {
                this.$message({
                    type: 'error',
                    message: '请输入拒收原因'
                })
                return;
            }
            if (this.selectedItem == '') {
                this.$message({
                    type: 'error',
                    message: '请选择风险类型'
                })
                return;
            }
            var data = {
                content: this.rejectionTextarea,
                ogid: this.checkOrderId,
                id: localStorage.getItem('id'),
                status: this.selectedItem
            }
            this.$http.post('/receiving/rejection', data, (res) => {
                if (res.code == 1) {
                    this.$message({
                        type: 'success',
                        message: '拒收成功'
                    })
                    this.rejectionTextarea = ''
                    this.updateCategory = false;

                    this.orderInfo.id = '';
                    this.checkClothTypes = '';
                    this.checkClothColor = '';
                    this.riskContent = '';
                    this.notesContent = '';
                    this.flawlistIndex = [],
                        this.checkClothfiles = [];
                    this.checkFlawlist = [];
                    this.fileIndexList = [];
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg
                    });
                }
            })
        },
        printFile(item, index) {
            console.log(item, index);
            this.filePrint = item;
            this.fileIndex = index + 1;
            this.generateBarcodeAnnex();

        },
        // 选择衣物分类
        getClothType(type, id, index) {
            this.checkClothTypes = type;
            // this.activeName = 'seven';
            this.newGoodsId = id;
            this.tyIndex = index;
            console.log(this.checkClothTypes, this.newGoodsId);
        },
        // 选择衣物颜色分类
        getColor(item, index) {
            this.checkClothColor = item;
            this.colorIndex = index;
            this.activeName = 'three';
        },
        // 选择衣物附件
        getFile(item, indexs) {
            console.log(item);
            if (item == '无') {
                this.activeName = 'four';
            }
            if (this.checkClothfiles.includes(item)) {
                let index = this.checkClothfiles.indexOf(item);
                if (index !== -1) {
                    console.log(index);

                    this.checkClothfiles.splice(index, 1)
                }
            } else {
                this.checkClothfiles.push(item);

            };
            if (this.fileIndexList.includes(indexs)) {
                let index = this.fileIndexList.indexOf(indexs);
                if (index !== -1) {
                    this.fileIndexList.splice(index, 1)
                }
            } else {
                this.fileIndexList.push(indexs);
            }
        },
        // 选择瑕疵
        getFlaw(item, indexs) {
            if (this.checkFlawlist.includes(item)) {
                let index = this.checkFlawlist.indexOf(item);
                if (index !== -1) {
                    console.log(index);

                    this.checkFlawlist.splice(index, 1)
                }
            } else {
                this.checkFlawlist.push(item);

            };
            if (this.flawlistIndex.includes(indexs)) {
                let index = this.flawlistIndex.indexOf(indexs);
                if (index !== -1) {
                    this.flawlistIndex.splice(index, 1)
                }
            } else {
                this.flawlistIndex.push(indexs);
            }

        },
        getClothIndex(index) {
            this.clothindex = index
        },
        // 点击图片放大
        toBig(index) {
            this.showBigPhoto = true;
            this.bigPhotoUrl = this.capturedPhotos[index].url;
        },
        // 打印水洗唛回调
        handlePrint(x) {
            let air = ''
            if (x == 1) {
                air = '#printHost';
                this.subGoods(this.checkOrderId);
                this.activeName = 'two';
            } else if (x == 2) {
                air = '#printAnnex';
            }
            new VuePrintNext({
                el: air,
                preview: false
            });
        },
        // 删除照片回调
        deletePhoto(index) {
            this.capturedPhotos.splice(index, 1);
            this.ossUrls.splice(index, 1);
            this.Chaibaohotos.splice(index, 1);
            // this.riskPhoto.splice(index,1);
        },
        // 配置oss
        configureOSSClient() {
            try {
                this.ossClient = new OSS({
                    region: 'oss-cn-beijing',
                    accessKeyId: 'LTAI5tQa4tD4qCbSVTzghqYT',
                    accessKeySecret: 'D9paNucPU5qWZDX79ZcLINd9DlgmMC',
                    bucket: 'jiekexihu'
                });
            } catch (error) {
                console.error('配置 OSS 客户端时出错:', error);
            }
        },
        // 获取订单信息
        searchInfo() {
            this.getEntryTime();
            if (this.indexFlag == 2) {
                this.sendGoodsAdd();
                if (this.Rfid) {
                    this.$http.post('order/getByTransferCode', { transferCode: this.Rfid }, (res => {
                        if (res.code == 1) {
                            this.searchOrderTome(res.data.id);
                            this.orderInfo = res.data;
                            this.checkOrderId = res.data.goodsList[0]?.id;
                            this.goodsIndex = 0;
                            this.allGoodsPops = res.data.goodsList.reduce((acc, curr) => {
                                return acc + Number(curr.card_nums);
                            }, 0);
                            console.log(this.allGoodsPops);
                        }
                    }))
                    this.Rfid = '';
                } else {
                    this.$http.post('order/getByTransferCode', { transferCode: this.orderInfo.rfid }, (res => {
                        if (res.code == 1) {
                            this.searchOrderTome(res.data.id);
                            this.orderInfo = res.data;
                            this.checkOrderId = res.data.goodsList[0]?.id;
                            this.goodsIndex = 0;
                            this.allGoodsPops = res.data.goodsList.reduce((acc, curr) => {
                                return acc + Number(curr.card_nums);
                            }, 0);
                        }
                    }))
                    this.Rfid = '';
                }
            }
        },
        // 开启摄像头回调
        startCamera() {
            const video = this.$refs.video;
            if (navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices.getUserMedia({
                    video: {
                        width: { ideal: 3264 },
                        height: { ideal: 2448 },
                        frameRate: { ideal: 30, max: 30 }
                    }
                    // video:true
                })
                    .then(stream => {
                        if (!video) {
                            return
                        } else {
                            video.srcObject = stream;
                        }
                    })
                    .catch(error => {
                        console.error('摄像头访问错误:', error);
                    });
            } else {
                alert('您的浏览器不支持摄像头访问');
            }
        },
        // 拍照回调
        async captureImage() {
            const canvas = this.$refs.canvas;
            const video = this.$refs.video;
            if (!canvas) {
                return
            } else {
                canvas.getContext('2d').drawImage(video, 0, 0, 3264, 2448);
            }
            canvas.toBlob((blob) => {
                // 创建一个 URL 来表示 Blob 对象
                const url = URL.createObjectURL(blob);

                // 将照片对象添加到 photos 数组中
                this.Chaibaohotos.push({ url, blob });
                this.capturedPhotos.push({ url });
                this.uploadToOSS(blob);
            }, 'image/png');
        },
        // 上传到oss回调
        async uploadToOSS(blob) {
            if (this.capturedPhotos.length > 0) {
                try {
                    const fileName = `${Date.now()}`;
                    const file = new File([blob], 'image.png', { type: blob.type });
                    this.ossFile = file;
                    const result = await this.ossClient.put(fileName, this.ossFile, {
                        headers: {
                            'Content-Type': 'image/png',
                            'Content-Disposition': 'inline'
                        }
                    });
                    console.log('上传成功,OSS 图片 URL:', result.url);
                    // 把返回的oss地址存到一个数组中
                    this.ossUrls.push(result.url);
                    this.riskPhoto.push(result.url);
                    console.log(this.riskPhoto);

                } catch (error) {
                    console.log('上传失败', error);
                }
            } else {
                console.log('未拍摄商品照片');
            }
        },
        // 上传商品照片回调
        uploadPhotos() {
            let url = this.ossUrls
            console.log(url);
            if (url == '') {
                this.$message({
                    message: '请先拍照片,再上传  ',
                    type: 'error'
                })
            } else {
                this.$http.post(`/rfidlist/addImage?uid=${JSON.parse(localStorage.getItem('userinfo')).id}&goodsId=${this.checkOrderId}&photo=${url}`, null, (res) => {
                    if (res.code === 1) {
                        // console.log(res,'上传图片成功');
                        this.$message({
                            type: 'success',
                            message: '上传图片成功,请打印水洗唛'
                        })
                        this.capturedPhotos = [],
                            this.activeName = 'seven'
                    } else {
                        this.$message({
                            type: 'error',
                            message: res.msg
                        })
                    }
                });
                this.ossUrls = []
                this.Chaibaohotos = []
            }
        },
        // 生成主水洗唛条码回调
        generateBarcode() {
            const barcodeData = this.checkOrderId;
            const canvas = this.$refs.barcodeCanvas;
            if (canvas == undefined) {
                return;
            } else {
                JsBarcode(canvas, barcodeData, {
                    format: 'CODE128',
                    lineColor: '#000000',
                    width: 2,
                    height: 50,
                    displayValue: false,
                    margin: 0,
                    fontSize: 20
                });
                const dataUrl = canvas.toDataURL('image/png');
                this.barcodeUrl = dataUrl;
            }
        },
        // 生成附件水洗唛条码回调
        generateBarcodeAnnex() {
            const barcodeData = `${this.checkOrderId}-${this.fileIndex}`;
            const canvas = this.$refs.barcodeCanvasAnnex;
            if (canvas == undefined) {
                return;
            } else {
                JsBarcode(canvas, barcodeData, {
                    format: 'CODE128',
                    lineColor: '#000000',
                    width: 2,
                    height: 50,
                    displayValue: true,
                    margin: 0,
                    fontSize: 20
                });
                const dataUrl = canvas.toDataURL('image/png');
                this.barcodeUrlAnnex = dataUrl;
            }
        },
        // 删除商品弹框
        openDeleteGoods(id, rfid) {
            this.$confirm(`是否删除商品ID为${id}的商品?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post('order/delGoods', { ogId: id }, (res => {
                    if (res.code == 1) {
                        this.searchInfo(rfid);
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    }
                }))
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        }
    },
}
</script>

<style lang="scss" scoped>
.big {
    width: 100%;
    height: 100%;
    display: flex;

    .bigPhoto {
        z-index: 3;
        position: fixed;
    }

    .left {
        width: 320px;
        height: 830px;
        background-color: #FFFFFF;
        padding: 16px;
        overflow: auto;

        .left-top {
            display: flex;
            justify-content: space-between;
        }

        .left-content {
            div {
                .orderFlag {
                    margin-top: 20px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    font-size: 24px;
                    color: #3278FF;
                    line-height: 24px;
                    // text-align: center;
                    font-style: normal;
                }
            }

            .left-content-top {
                margin-top: 20px;
                margin-bottom: 20px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #666666;
                line-height: 16px;
                text-align: left;
                font-style: normal;

                p {
                    margin-top: 16px;
                }
            }

            .timeOut {
                background-image: url(../../assets/images/timeOut.png);
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 100px 100px;
            }

            .left-content-bottom {
                margin-top: 20px;

                .list {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;
                    width: 280px;
                    height: 104px;
                    background: #FFFFFF;

                    .list-ul {
                        padding: 10px;

                        li {
                            font-size: 17px;
                        }
                    }

                    .alreadySub {
                        background-image: url(../../assets/images/alreadySub.png);
                        background-size: 80px 80px;
                        background-repeat: no-repeat;
                        background-position: 115px 10px;
                    }
                }

                .list-add {
                    background-color: rgb(245, 247, 247);
                    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
                    border-radius: 5px;
                    border: 2px dashed #666666;

                }
            }

            .left-content-btn {
                position: fixed;
                top: 850px;
                left: 270px;

            }
        }
    }

    .right {
        width: 1408px;
        height: 830px;
        background-color: #FFFFFF;

        .right-top {
            .allCloth {
                height: 580px;
                padding: 10px;

                .right-top-title {
                    ul {
                        display: flex;

                        .clothTypeList {
                            width: 112px;
                            height: 48px;
                            line-height: 48px;
                            text-align: center;
                            margin-top: 10px;
                            margin-right: 16px;
                            border-radius: 24px;
                            background-color: #F5F5F5;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 500;
                            font-size: 18px;
                            color: #666666;
                            text-align: center;
                            font-style: normal;
                        }

                        .add {
                            background-color: #0486FE;
                            color: #FFFFFF;
                        }
                    }
                }

                .cloth {
                    display: flex;
                    flex-wrap: wrap;
                    height: 400px;
                    overflow: auto;

                    .clothItem {
                        width: 88px;
                        height: 138px;
                        margin-right: 10px;
                        margin-top: 8px;
                        text-align: center;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 600;
                        font-size: 16px;
                        color: #2D4164;
                        line-height: 22px;
                        font-style: normal;

                        img {
                            width: 82px;
                            height: 70px;
                            margin-bottom: 10px;
                        }
                    }

                    .ccc {
                        background: rgba(216, 216, 216, 0);
                        border-radius: 4px;
                        border: 2px solid #3278FF;
                    }
                }
            }


            .pohtoArea {
                display: flex;
                width: 100%;
                height: 580px;
                padding: 24px;

                .videoArea {
                    margin-right: 10px;

                    .videoArea-btn-list {
                        // width: 590px;
                        height: 50px;

                        button {
                            width: 285px;
                            height: 50px;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 500;
                            font-size: 18px;
                            color: #FFFFFF;
                            font-style: normal;
                        }
                    }
                }

                .photoItem {
                    height: 500px;
                    overflow: auto;
                    display: flex;
                    flex-wrap: wrap;
                    box-sizing: border-box;

                    .item {
                        width: 165px;
                        height: 124px;
                        position: relative;
                        margin-right: 20px;
                        margin-top: 5px;

                        img {
                            width: 160px;
                            height: 119px;
                        }
                    }

                    .item-add {
                        border: 2px dashed red;
                    }
                }
            }

            .fileList {
                height: 580px;

                ul {
                    display: flex;
                    flex-wrap: wrap;
                    text-align: center;

                    .fileListItem {
                        border-radius: 24px;
                        padding-left: 10px;
                        padding-right: 10px;
                        font-size: 18px;
                        color: #666666;
                        line-height: 48px;
                        height: 48px;
                        background-color: #F5F5F5;
                        margin: 10px;
                    }

                    .xxx {
                        background-color: #0486FE;
                        color: #FFFFFF;
                    }
                }
            }

            .colorList {
                height: 580px;

                ul {
                    display: flex;
                    flex-wrap: wrap;
                    text-align: center;

                    .colorListItem {
                        border-radius: 24px;
                        padding-left: 10px;
                        padding-right: 10px;
                        font-size: 18px;
                        color: #666666;
                        line-height: 48px;
                        height: 48px;
                        background-color: #F5F5F5;
                        margin: 10px;

                    }

                    .xxx {
                        background-color: #0486FE;
                        color: #FFFFFF;
                    }
                }
            }

            .flawList {
                height: 580px;

                ul {
                    display: flex;
                    flex-wrap: wrap;
                    text-align: center;

                    .flawListItem {
                        border-radius: 24px;
                        padding-left: 10px;
                        padding-right: 10px;
                        font-size: 18px;
                        color: #666666;
                        line-height: 48px;
                        height: 48px;
                        background-color: #F5F5F5;
                        margin: 10px;
                    }

                    .xx {
                        background-color: #0486FE;
                        color: #FFFFFF;
                    }
                }
            }

            .riskList {
                display: flex;
                height: 560px;
                margin-top: 20px;

                .riskList-left {
                    width: 500px;
                    margin-right: 52px;

                    p {
                        margin-top: 32px;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 400;
                        font-size: 18px;
                        color: #000000;
                        line-height: 25px;
                        text-align: left;
                        font-style: normal;
                    }

                    .riskImgList {
                        margin: 10px;
                        width: 500px;
                        height: 250px;
                        padding: 5px;
                        overflow: auto;
                        display: flex;
                        flex-wrap: wrap;

                        .riskImgList-item {
                            position: relative;
                            width: 100px;
                            height: 20px;
                            margin: 10px;

                            button {
                                position: absolute;
                                width: 20px;
                                right: 0;
                            }

                            img {
                                width: 98px;
                                height: 76px;
                            }
                        }
                    }
                }

                .riskList-right {
                    width: 700px;
                    overflow: auto;
                    position: relative;

                    .riskList-rightImg {
                        display: flex;
                        padding: 5px;
                        height: 450px;
                        flex-wrap: wrap;
                        overflow: auto;

                        .riskList-rightImg-item {
                            width: 160px;
                            height: 119px;
                            position: relative;
                            margin: 16px;

                            img {
                                width: 160px;
                                height: 119px;
                            }

                            .riskList-btn {
                                position: absolute;
                                width: 160px;
                                height: 50px;
                                background-color: #3278FF;
                                left: 0;
                                top: 70px;
                                font-family: PingFangSC, PingFang SC;
                                font-weight: 500;
                                font-size: 18px;
                                color: #FFFFFF;
                                line-height: 50px;
                                text-align: center;
                                font-style: normal;
                            }
                        }
                    }
                }

                // ul {
                //     display: flex;
                //     flex-wrap: wrap;
                //     text-align: center;

                //     li {
                //         border-radius: 10px;
                //         padding-left: 10px;
                //         padding-right: 10px;
                //         font-size: 16px;
                //         line-height: 50px;
                //         height: 50px;
                //         // background-color: rgb(235, 238, 238);
                //         margin: 10px;
                //     }
                // }
            }
        }

        .printArea {
            height: 200px;
            display: flex;

            .host {
                width: 100%;
                // border:1px solid black;
                margin-right: 20px;

                .hostPrint {
                    display: flex;

                    .printHost-right {
                        width: 200px;
                        height: 150px;
                        overflow-wrap: break-word;
                    }

                    .printHost-left {
                        width: 225px;
                    }
                }
            }
        }
    }
}
</style>